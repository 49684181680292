<template>
    <middle-loading :loading="loading" v-if="loading" />

    <van-form v-else @submit="onSubmit">
        <van-field
            v-model="form.nickname"
            name="title"
            label="昵称"
            placeholder="请输入昵称"
            required
            :rules="[{ required: true, message: '请输入昵称' }]"
        ></van-field>

        <van-field required name="uploader" label="头像">
            <template #input>
                <van-uploader :after-read="afterRead" :max-count="1" v-model="fileList" />
            </template>
        </van-field>
        <van-field
            v-model="form.bio"
            rows="2"
            autosize
            label="简介"
            type="textarea"
            maxlength="100"
            placeholder="一句话介绍自己"
            show-word-limit
        ></van-field>

        <div style="margin: 16px;">
            <van-button
                round
                block
                type="info"
                native-type="submit"
                :loading="submitting"
                loading-size="16px"
                loading-text="提交中..."
                :disabled="submitting"
            >提交</van-button>
        </div>
    </van-form>
</template>

<script>
import { updateUser, getUserInfo, getUploadPayload } from '@/api/user'
import { LOGIN_USER } from '@/utils/constants'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'UserEdit',
    components: { MiddleLoading },
    props: {
        sermon: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            loading: true,
            submitting: false,
            form: {
                nickname: null,
                bio: '',
                avatar: null
            },
            fileList: []
        }
    },
    methods: {
        afterRead ({ file, content }) {
            this.fileList = []
            this.fileList.push({
                content: content,
                status: 'uploading'
            })
            getUploadPayload({
                upload_type: 'avatars',
                filename: file.name,
                content_type: file.type
            }).then(res => {
                let { action, url, upload_params } = res.data

                const form = new FormData()
                Object.keys(upload_params).forEach((key) => {
                    form.append(key, upload_params[key]);
                });

                form.append('file', file)
                this.$http.post(action, form, {
                    contentType: false,
                    processData: false,
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(() => {
                    this.form.avatar = url
                    this.fileList = []
                    this.fileList.push({
                        url: url,
                        status: 'done'
                    })
                })
            })
        },
        onSubmit() {
            this.submitting = true
            updateUser(this.form).then(res => {
                this.$ls.set(LOGIN_USER, res.data)
                this.$notify({ type: 'success', message: res.message })
                this.$router.push({ path: '/user' })
            }).finally(() => {
                this.submitting = false
            })
        }
    },
    created() {
        getUserInfo().then(res => {
            this.form = res.data
            this.form.bio = this.form.bio || ''
            this.fileList.push({
                url: this.form.avatar,
                status: 'done'
            })
        }).finally(() => {
            this.loading = false
        })
    }
};
</script>
